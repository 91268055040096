import { AxiosError } from "axios";
import { SignInCredentials } from "models";
import { getPointsHistory } from "services/achievement.service";
import { api } from "services/api";
import { fetchUser, signInRequest } from "services/auth.service";
import { getAllChallenges } from "services/challenges.service";
import { setAuthorizationHeader } from "services/interceptors";
import { findPlayer, updateUserStatus } from "services/profile.service";
import { User } from "types/user";
import { clearStorage, getStorageItem, setStorageItem } from "utils/storage";
import { create } from "zustand";

type AppStore = {
  user: User | null;
  token: string;
  host: string;
  shouldRedirect: boolean;
  setStoreHost: (host: string) => void;
  setStoreToken: (token: string) => void;
  setStoreUser: (newUser: User) => void;
  setShouldRedirect: (shouldRedirect: boolean) => void;
  clearStoreUser: () => void;
  restoreSession: () => void;
  refreshUser: () => Promise<User | undefined>;
  loginWithToken: (access_token: string) => Promise<User | undefined>;
  login: ({
    username,
    password,
  }: SignInCredentials) => Promise<User | undefined>;
  hasItem: (key: string) => boolean;
  hasCompletedChallenge: (key: string) => boolean;
  hasBadge: (key: string) => boolean;
  hasFinishedOnboarding: () => boolean;
  findPlayer: (_id: string) => Promise<User | undefined>;
  pointsHistory: any;
  loadAllChallenges: () => any;
  getChallengeById: (id: string) => any;
  challenges: any;
};

export const useAppStore = create<AppStore>((set, get) => ({
  user: null,
  token: "",
  host: "",
  shouldRedirect: false,
  pointsHistory: {},
  setStoreHost: (host: string) => {
    set({ host });
  },
  setStoreToken: (token: string) => {
    set({ token });
    setStorageItem("token", token);
  },
  setStoreUser: (newUser: User) => {
    set({ user: newUser });
    setStorageItem("user", newUser);
  },
  clearStoreUser: () => {
    clearStorage();
    setAuthorizationHeader(api.defaults, "");
    set({ user: undefined, token: "", host: "" });
  },
  setShouldRedirect: (shouldRedirect: boolean) => {
    set({ shouldRedirect });
  },
  refreshUser: async () => {
    try {
      const user = get().user;
      if (!user) return;
      const updatedUser = await updateUserStatus();
      const pointsHistory = await getPointsHistory(user._id);
      set({ pointsHistory });
      get().setStoreUser(updatedUser);
      return updatedUser;
    } catch (error) {
      throw error;
    }
  },
  restoreSession: () =>
    set((state: AppStore) => {
      const restoredUser = getStorageItem("user");
      const restoredToken = getStorageItem("token");

      if (restoredUser && restoredToken) {
        get().loadAllChallenges();
        return {
          ...state,
          user: restoredUser,
          token: restoredToken,
        };
      } else {
        return state;
      }
    }),
  loginWithToken: async (access_token: string) => {
    try {
      if (!access_token) {
        return;
      }

      setAuthorizationHeader(api.defaults, access_token);

      const data = await fetchUser();
      const { name, image, extra } = data;
      const userData = {
        _id: data._id,
        ...data,
        extra,
        name,
        image,
        permissions: null,
        roles: null,
      };

      get().setStoreUser(userData);
      get().setStoreToken(access_token);

      return userData;
    } catch (error) {
      const err = error as AxiosError;
      console.log(err);
      throw err;
    }
  },
  login: async ({ username, password }: SignInCredentials) => {
    try {
      const auth = await signInRequest({ username, password });

      if (auth.message) {
        throw auth;
      }

      const { access_token, refreshToken, permissions, roles } = auth;

      setAuthorizationHeader(api.defaults, access_token);

      const data = await fetchUser();
      const userData = { _id: data._id, ...data, permissions, roles };

      // console.log(userData);

      get().setStoreToken(access_token);
      get().setStoreUser(userData);

      return userData;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
  hasCompletedChallenge: (key: string) => {
    return get().user?.challenges && get().user?.challenges[key];
  },
  hasItem: (key: string) => {
    const user = get().user;

    if (!user) return false;

    let result = false;

    switch (key) {
      case "DPSpetX":
        result = !!user.extra.onboarding_darklands;
        break;
      case "DPUwGBr":
        result = !!user.extra.onboarding_junkcity;
        break;
      case "DPUw5Eu":
        result = !!user.extra.onboarding_ocean_of_feelings;
        break;
      case "DPUzkSx":
        result = !!user.extra.onboarding_prosperity_forest;
        break;
      default:
        break;
    }

    // let result = user.challenges && user.challenges[key];
    // if (!result && user?.challenge_progress) {
    //   const progress = user?.challenge_progress.find(
    //     (p) => p.challenge === key
    //   );
    //   if (progress) result = progress.rules_completed > 0;
    // }

    return result;
  },
  hasBadge: (key: string) => {
    const user = get().user;
    if (!user) return false;
    let result = user.challenges && user.challenges[key];
    return result;
  },
  findPlayer: async (_id: string) => {
    try {
      const user = await findPlayer(_id);
      return user as User;
    } catch (error) {
      throw error;
    }
  },
  hasFinishedOnboarding: () => {
    const user = get().user;
    return (
      user &&
      user.extra.onboarding &&
      user.extra.onboarding_current === "finished"
    );
  },
  loadAllChallenges: async () => {
    try {
      if (!get().challenges.length) {
        console.log("loadAllChallenges");
        const challenges = await getAllChallenges();
        set({ challenges });
      }
    } catch (error) {
      // throw error;
    }
  },
  getChallengeById: (id: string) => {
    return get().challenges.find((c: any) => c._id === id);
  },
  challenges: [],
}));
