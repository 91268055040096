import { Download, Trash } from "@phosphor-icons/react";
import { useModal } from "context/ModalContext";
import { Button, Textarea } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import ReactModal from "react-modal";
import { actionLog } from "services/actionlog.service";
import { clearNotifications } from "services/notifications.service";
import {
  getRealLifeLog,
  submitRealLifeMission,
} from "services/reallife.service";
import { uploadFile } from "services/upload.service";
import { useAppStore } from "store";

export default function RealLifeReviewModal() {
  ReactModal.setAppElement("#modal");

  const modalID = "real-life-review";
  const { user, host, pointsHistory } = useAppStore();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { showModal, openModal, closeModal, modalData } = useModal();

  const [loading, setLoading] = useState(false);

  const [answers, setAnswers] = useState<any>([]);

  function afterOpenModal() {}

  const handleCloseModal = useCallback(() => {
    closeModal();
    setLoading(false);
  }, [closeModal]);

  const loadData = useCallback(async () => {
    try {
      if (!modalData) return;
      const result = await getRealLifeLog(modalData._id, user?._id);
      setAnswers(result);
    } catch (e) {
      console.log(e);
    }
  }, [modalData, user]);

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
      loadData();
    } else {
      setIsOpen(false);
    }
  }, [openModal, modalData, loadData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 md:px-5 w-[450px] max-w-[97vw] max-h-[80vh] flex flex-col items-center flex-none mx-5 gap-3 overflow-y-auto">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                ¡Respuestas Vida Real!
              </h1>
              <small>Ver las respuestas que enviaste a esta misión</small>
              {!loading ? (
                <>
                  <div className="w-full flex flex-col gap-3">
                    <p className="text-center text-lg font-bold">
                      {modalData?.title}
                    </p>
                    {answers?.map((answer: any, index: number) => (
                      <div
                        key={index}
                        className="border p-3 rounded-xl flex flex-col gap-3"
                      >
                        {answer.message ? (
                          <div>
                            <p className="">Usted dijo:</p>
                            <p className="font-bold font-italic">
                              {answer.message}
                            </p>
                          </div>
                        ) : null}

                        {answer.image && answer.image.url ? (
                          <img
                            src={answer.image.url}
                            alt="answer"
                            className="w-full rounded-lg mx-auto object-contain object-center h-40 bg-gray-100"
                          />
                        ) : null}
                        {answer.file && answer.file.url ? (
                          <div className="flex items-center justify-between">
                            <a
                              href={answer.file.url}
                              target="_blank"
                              rel="noreferrer"
                              className="bg-brand-green w-full block p-3 rounded gap-3 flex items-center justify-center text-white font-bold underline "
                            >
                              <Download size={24} />
                              <span>Descargar archivo</span>
                            </a>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div>
                  <p>CARGANDO...</p>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
}
