import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { QRCodeSVG } from "qrcode.react";
import { Button } from "flowbite-react";
import { useModal } from "context/ModalContext";
import { useAppStore } from "store";
import { Gift } from "@phosphor-icons/react";
import { getPurchase } from "services/store.service";

export function PurchaseModal(props: any) {
  ReactModal.setAppElement("#modal");

  const modalID = "purchase";

  const { user } = useAppStore();

  const [modalIsOpen, setIsOpen] = useState(false);
  const { openModal, closeModal, showModal, modalData } = useModal();

  const [loading, setLoading] = useState(false);
  const [purchaseData, setPurchaseData] = useState<any>();

  async function handleCloseModal() {
    closeModal();
  }

  function afterOpenModal() {}

  const handleShowTerms = useCallback(() => {
    showModal("purchase-terms", modalData);
  }, [showModal, modalData]);

  const loadData = useCallback(async () => {
    setLoading(true);
    if (!user) return;
    // Load data
    // console.log(user);
    const purchase = await getPurchase(user._id, modalData._id);
    if (purchase) {
      if (
        purchase.extra?.status === "USED" ||
        purchase.extra?.status === "EXPIRED"
      ) {
        setPurchaseData(null);
      } else {
        setPurchaseData(purchase);
      }
    } else {
      setPurchaseData(null);
    }
    setLoading(false);
  }, [user, modalData]);

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
      loadData();
    } else {
      setIsOpen(false);
    }
  }, [openModal, loadData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 px-10 w-[450px] flex flex-col items-center flex-none mx-5 gap-3">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                Premio redimido
              </h1>
              {loading ? (
                <div className="text-center p-3 font-bold">
                  <p>CARGANDO...</p>
                </div>
              ) : null}

              {!loading && purchaseData ? (
                <>
                  <div className="relative my-3">
                    <QRCodeSVG
                      value={purchaseData._id}
                      width={200}
                      height={200}
                    />
                  </div>
                  <div>
                    <p className="text-center">
                      Muestre este código QR
                      <br />
                      al administrador del premio
                    </p>
                  </div>
                </>
              ) : null}

              {!loading && !purchaseData ? (
                <div className="relative p-3 font-bold text-xl text-center border rounded-xl border-brand-red">
                  <Gift size={48} className="text-brand-red mx-auto" />
                  <p className="leading-tight">
                    Este premio ya ha sido utilizado o ha caducado.
                  </p>
                </div>
              ) : null}

              <div className="py-3 relative z-10 flex gap-3">
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleCloseModal()}
                  className="bg-brand-red"
                >
                  CERRAR
                </Button>
                <Button
                  fullSized
                  type="button"
                  onClick={() => handleShowTerms()}
                  className="whitespace-nowrap"
                >
                  VER DETALLES
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
