import { useModal } from "context/ModalContext";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { purchaseItem } from "services/store.service";
import { useAppStore } from "store";

export default function PurchaseTermsModal() {
  ReactModal.setAppElement("#modal");

  const modalID = "purchase-terms";
  const { user, refreshUser, host, pointsHistory } = useAppStore();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { showModal, openModal, closeModal, modalData } = useModal();

  const [loading, setLoading] = useState(false);

  const [answers, setAnswers] = useState<any>([]);

  const [purchaseError, setPurchaseError] = useState<any>();

  function afterOpenModal() {}

  const handleCloseModal = useCallback(() => {
    closeModal();
    setLoading(false);
  }, [closeModal]);

  const handlePurchase = useCallback(async () => {
    if (!modalData || !user) {
      return;
    }

    try {
      setLoading(true);
      const result = await purchaseItem(modalData._id, user._id);
      if (result.status === "OK") {
        refreshUser();
        showModal("purchase", modalData);
      } else {
        setPurchaseError(
          "No cumples los requisitos para comprar este artículo.",
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [modalData, user, refreshUser, showModal]);

  const isPurchased = useCallback(
    (good?: any) => {
      if (!user?.catalog_items) return false;
      try {
        const purchased = Object.keys(user?.catalog_items).find((item: any) => {
          return item === good;
        });
        return !!purchased;
      } catch (e) {
        return false;
      }
    },
    [user],
  );

  useEffect(() => {
    setPurchaseError(null);
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 md:px-5 w-[450px] max-w-[97vw] max-h-[80vh] flex flex-col items-center flex-none mx-5 gap-3 overflow-y-auto">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                Tienda Premios
              </h1>
              <small>
                Antes de canjear, lea las condiciones de uso de este premio.
              </small>
              {!loading ? (
                <>
                  {!purchaseError ? (
                    <div className="w-full flex flex-col gap-3 bg-gray-100 rounded-lg p-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: modalData?.terms || "",
                        }}
                      ></div>
                    </div>
                  ) : null}
                  {purchaseError && (
                    <div className="text-red-500 text-center">
                      {purchaseError}
                    </div>
                  )}
                  <div className="py-3 relative z-10 flex gap-2">
                    <Button
                      fullSized
                      type="button"
                      onClick={() => handleCloseModal()}
                      className="bg-red-500"
                    >
                      CERRAR
                    </Button>
                    {!isPurchased(modalData) ? (
                      <Button
                        fullSized
                        type="button"
                        onClick={() => handlePurchase()}
                        className="bg-brand-green whitespace-nowrap"
                      >
                        CANJEAR PREMIO
                      </Button>
                    ) : null}
                  </div>
                </>
              ) : (
                <div>
                  <p>CARGANDO...</p>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
}
