import { api } from './api';

export async function getVirtualGoods() {
  try {
    const { data } = await api.get('virtualgoods/item?player=me&orderby=extra.order&reverse=false');
    return data;
  } catch (error) {
    return error;
  }
}

export async function getExperienceCatalog() {
  try {
    const { data } = await api.get('database/experiencias__c');
    return data;
  } catch (error) {
    return error;
  }
}

export async function getVirtualGood(_id: string) {
  try {
    const { data } = await api.get(`virtualgoods/item/${_id}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function purchaseItem(item: string, player: string) {
  try {
    const { data } = await api.post('virtualgoods/purchase', {
      item,
      player,
    });
    return data;
  } catch (error) {
    return error;
  }
}

export async function getStore(player: string) {
  try {
    const { data } = await api.post(`find/store`, {
      player
    });

    if (Array.isArray(data)) {
      return data[0];
    }
    return data;
  } catch (error) {
    return error;
  }
}


export async function getPurchase(player: string, item: string) {
  try {
    const { data } = await api.post(`find/purchases`, {
      player,
      item
    });

    if (Array.isArray(data)) {
      return data[0];
    }
    return data;
  } catch (error) {
    return error;
  }
}
