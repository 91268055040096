import { Trash } from "@phosphor-icons/react";
import { useModal } from "context/ModalContext";
import { Button, Textarea } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import ReactModal from "react-modal";
import { actionLog } from "services/actionlog.service";
import { clearNotifications } from "services/notifications.service";
import { submitRealLifeMission } from "services/reallife.service";
import { uploadFile } from "services/upload.service";
import { useAppStore } from "store";

export default function RealLifeAnswerModal() {
  ReactModal.setAppElement("#modal");

  const modalID = "real-life-answer";

  const [modalIsOpen, setIsOpen] = useState(false);
  const { showModal, openModal, closeModal, modalData } = useModal();

  const [answer, setAnswer] = useState("");
  const [answerFile, setAnswerFile] = useState<File | null>(null);

  const [loading, setLoading] = useState(false);

  const [imagePreview, setImagePreview] = useState<any>();

  const { user, host, pointsHistory } = useAppStore();

  function afterOpenModal() {}

  const clearFile = useCallback(() => {
    setAnswerFile(null);
    setImagePreview(null);

    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }, []);

  const handleCloseModal = useCallback(() => {
    closeModal();
    setLoading(false);
    setAnswer("");
    setAnswerFile(null);
    clearFile();
  }, [clearFile, closeModal]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    console.log(modalData);

    if (!answer && !answerFile) {
      alert("Debes escribir una respuesta o subir un archivo.");
      setLoading(false);
      return;
    }

    let fileURL = "";
    let imageURL = "";

    // if answerFile is an image, upload it at the API first
    if (answerFile) {
      const result = await uploadFile(answerFile, {
        session: "real_life",
      });

      if (result && result.uploads) {
        if (answerFile.type.includes("image")) {
          imageURL = result.uploads[0].url;
        } else {
          fileURL = result.uploads[0].url;
        }
      }
    }

    const submission = await submitRealLifeMission({
      parent: modalData?._id,
      player: user?._id,
      message: answer,
      file: {
        url: fileURL,
      },
      image: {
        url: imageURL,
      },
    });

    await actionLog("real_life", {
      content_id: modalData?._id,
      answer_id: submission._id,
    });

    handleCloseModal();
  }, [modalData, user, answer, answerFile, handleCloseModal]);

  useEffect(() => {
    if (openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  useEffect(() => {
    // if its an image file, display preview
    // display base64 image preview
    if (answerFile) {
      // check if any file is too big
      if (answerFile.size > 1024 * 1024 * 2) {
        alert("El archivo es demasiado grande. Máximo 2MB.");
        clearFile();
        return;
      }

      // check if file is an image
      if (!answerFile.type.includes("image")) {
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(answerFile);
    }
  }, [answerFile, clearFile]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Badge Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-white rounded-xl p-3 md:px-5 w-[450px] max-w-[97vw] max-h-[80vh] flex flex-col items-center flex-none mx-5 gap-3 overflow-y-auto">
              <h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
                ¡Respuestas Vida Real!
              </h1>
              <small>Escribe tu respuesta o sube una imagen o archivo.</small>
              {!loading ? (
                <>
                  <div className="w-full flex flex-col gap-3">
                    <Textarea
                      placeholder="Escribe aquí tu respuesta"
                      onChange={(e) => setAnswer(e.target.value)}
                      className="h-[160px]"
                    />
                    <div className="bg-gray-100 p-3 rounded-lg">
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            setAnswerFile(file);
                          }
                        }}
                      />
                      {imagePreview && (
                        <div className="relative">
                          <button
                            onClick={() => {
                              clearFile();
                            }}
                            className="absolute -top-4 -right-2 bg-red-500 text-white rounded-full p-2 border-2 border-white shadow-lg"
                          >
                            <Trash size={28} />
                          </button>
                          <img
                            src={imagePreview}
                            alt="preview"
                            className="w-full max-h-[200px] object-cover rounded-lg mt-3"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="py-3 relative z-10 flex gap-2">
                    <Button
                      fullSized
                      type="button"
                      onClick={() => handleCloseModal()}
                      className="bg-red-500"
                    >
                      CERRAR
                    </Button>
                    <Button
                      fullSized
                      type="button"
                      onClick={() => handleSubmit()}
                    >
                      ENVIAR
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  <p>CARGANDO...</p>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
}
